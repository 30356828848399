/* --- setup --- */

:root {
    --rgb-blue-100: rgb(235, 243, 248);
    --rgb-blue-200: rgb(198, 222, 242);
    --rgb-blue-300: rgb(130, 182, 230);
    --rgb-blue-400: rgb(54, 138, 217);
    --rgb-blue-500: rgb(6, 111, 209);
    --rgb-blue-600: rgb(9, 95, 177);
    --rgb-blue-700: rgb(14, 70, 125);
    --rgb-blue-800: rgb(18, 48, 78);
    --rgb-blue-900: rgb(20, 36, 53);

    --rgb-indigo-100: rgb(237, 242, 249);
    --rgb-indigo-200: rgb(210, 220, 247);
    --rgb-indigo-300: rgb(159, 177, 243);
    --rgb-indigo-400: rgb(102, 129, 238);
    --rgb-indigo-500: rgb(66, 99, 235);
    --rgb-indigo-600: rgb(57, 86, 197);
    --rgb-indigo-700: rgb(43, 65, 137);
    --rgb-indigo-800: rgb(30, 46, 83);
    --rgb-indigo-900: rgb(23, 36, 54);

    --rgb-purple-100: rgb(241, 241, 248);
    --rgb-purple-200: rgb(230, 212, 241);
    --rgb-purple-300: rgb(210, 159, 227);
    --rgb-purple-400: rgb(188, 99, 211);
    --rgb-purple-500: rgb(174, 62, 201);
    --rgb-purple-600: rgb(143, 56, 170);
    --rgb-purple-700: rgb(94, 47, 121);
    --rgb-purple-800: rgb(50, 39, 77);
    --rgb-purple-900: rgb(27, 34, 53);

    --rgb-pink-100: rgb(243, 240, 245);
    --rgb-pink-200: rgb(238, 210, 223);
    --rgb-pink-300: rgb(230, 153, 182);
    --rgb-pink-400: rgb(220, 90, 136);
    --rgb-pink-500: rgb(214, 51, 108);
    --rgb-pink-600: rgb(175, 47, 96);
    --rgb-pink-700: rgb(113, 41, 76);
    --rgb-pink-800: rgb(58, 36, 59);
    --rgb-pink-900: rgb(28, 34, 49);

    --rgb-red-100: rgb(243, 241, 243);
    --rgb-red-200: rgb(238, 211, 213);
    --rgb-red-300: rgb(230, 156, 158);
    --rgb-red-400: rgb(220, 95, 96);
    --rgb-red-500: rgb(214, 57, 57);
    --rgb-red-600: rgb(175, 52, 55);
    --rgb-red-700: rgb(113, 44, 52);
    --rgb-red-800: rgb(58, 37, 49);
    --rgb-red-900: rgb(28, 34, 47);

    --rgb-orange-100: rgb(244, 242, 241);
    --rgb-orange-200: rgb(244, 220, 204);
    --rgb-orange-300: rgb(245, 178, 134);
    --rgb-orange-400: rgb(246, 132, 56);
    --rgb-orange-500: rgb(247, 103, 7);
    --rgb-orange-600: rgb(202, 89, 15);
    --rgb-orange-700: rgb(130, 67, 28);
    --rgb-orange-800: rgb(65, 47, 39);
    --rgb-orange-900: rgb(30, 36, 45);

    --rgb-yellow-100: rgb(244, 245, 240);
    --rgb-yellow-200: rgb(244, 231, 202);
    --rgb-yellow-300: rgb(245, 205, 130);
    --rgb-yellow-400: rgb(245, 177, 50);
    --rgb-yellow-500: rgb(245, 159, 0);
    --rgb-yellow-600: rgb(200, 134, 9);
    --rgb-yellow-700: rgb(128, 94, 24);
    --rgb-yellow-800: rgb(64, 57, 38);
    --rgb-yellow-900: rgb(30, 38, 45);

    --rgb-green-100: rgb(236, 245, 243);
    --rgb-green-200: rgb(206, 235, 215);
    --rgb-green-300: rgb(149, 215, 162);
    --rgb-green-400: rgb(86, 193, 104);
    --rgb-green-500: rgb(47, 179, 68);
    --rgb-green-600: rgb(42, 150, 64);
    --rgb-green-700: rgb(34, 103, 57);
    --rgb-green-800: rgb(26, 61, 51);
    --rgb-green-900: rgb(22, 39, 48);

    --rgb-teal-100: rgb(235, 245, 245);
    --rgb-teal-200: rgb(199, 232, 225);
    --rgb-teal-300: rgb(132, 208, 188);
    --rgb-teal-400: rgb(58, 182, 146);
    --rgb-teal-500: rgb(12, 166, 120);
    --rgb-teal-600: rgb(14, 139, 105);
    --rgb-teal-700: rgb(17, 97, 82);
    --rgb-teal-800: rgb(19, 59, 61);
    --rgb-teal-900: rgb(21, 38, 50);

    --rgb-cyan-100: rgb(236, 245, 247);
    --rgb-cyan-200: rgb(202, 232, 237);
    --rgb-cyan-300: rgb(138, 207, 218);
    --rgb-cyan-400: rgb(67, 179, 197);
    --rgb-cyan-500: rgb(23, 162, 184);
    --rgb-cyan-600: rgb(23, 136, 157);
    --rgb-cyan-700: rgb(22, 95, 113);
    --rgb-cyan-800: rgb(21, 58, 73);
    --rgb-cyan-900: rgb(21, 38, 52);

    --rgb-grey-100: rgb(239, 243, 246);
    --rgb-grey-200: rgb(218, 224, 230);
    --rgb-grey-300: rgb(179, 187, 200);
    --rgb-grey-400: rgb(135, 147, 166);
    --rgb-grey-500: rgb(108, 122, 145);
    --rgb-grey-600: rgb(91, 104, 125);
    --rgb-grey-700: rgb(63, 76, 94);
    --rgb-grey-800: rgb(38, 50, 66);
    --rgb-grey-900: rgb(24, 36, 51);

    --rgb-white: rgb(244, 248, 250);
    --rgb-black: rgb(21, 33, 47);

    --shadow:      0 0 1rem 0 rgb(var(--rgb-grey-600), var(--alpha-20));

    --text:        var(--rgb-grey-700);
    --text-alt:    var(--rgb-grey-300);
    --bg-main:     var(--rgb-white);
    --bg-alt:      var(--rgb-blue-200);
    --borders:     var(--rgb-blue-300);
    --borders-alt: var(--rgb-grey-100);
    --primary:     var(--rgb-blue-500);
    --secondary:   var(--rgb-orange-500);

    --font-size-xs: calc(var(--font-size-md) * 0.75);
    --font-size-sm: calc(var(--font-size-md) * 0.875);
    --font-size-md: 18px;
    --font-size-lg: calc(var(--font-size-md) * 1.125);
    --font-size-xl: calc(var(--font-size-md) * 1.250);

    --font-weight-xlight: 200;
    --font-weight-light:  300;
    --font-weight-normal: 400;
    --font-weight-semi:   500;
    --font-weight-bold:   600;
    --font-weight-xbold:  700;

    --base-margin: 1.66667rem;
    --margin-xs: calc(var(--base-margin) * 0.50);
    --margin-sm: calc(var(--base-margin) * 0.75);
    --margin-md: calc(var(--base-margin) * 1.00);
    --margin-lg: calc(var(--base-margin) * 1.25);
    --margin-xl: calc(var(--base-margin) * 1.50);

    font-family: inter, system-ui, ui-sans-serif, sans-serif;
    font-size: var(--font-size-md);
    font-feature-settings: "calt", "cv10", "cv11", "liga", "ss01", "ss03";
    font-variation-settings: normal;
    letter-spacing: -0.01111rem;
    -webkit-font-smoothing: antialiased;
}

@media (prefers-color-scheme: dark) {
    :root {
        --shadow:      0 0 1rem 0 rgb(var(--rgb-grey-200), var(--alpha-20));

        --text:        var(--rgb-grey-100);
        --text-alt:    var(--rgb-grey-400);
        --bg-main:     var(--rgb-grey-800);
        --bg-alt:      var(--rgb-grey-700);
        --borders:     var(--rgb-grey-800);
        --borders-alt: var(--rgb-grey-500);
        --primary:     var(--rgb-blue-300);
        --secondary:   var(--rgb-orange-500);
    }
}

@media (prefers-reduced-motion: no-preference) {
    :root {
        scroll-behavior: smooth;
    }
}

@media screen and (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.001ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.001ms !important;
    }
}

*, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    -webkit-text-size-adjust: 100%;
}

html, body {
    height: 100%;
}


/* --- page elements --- */

body {
    display: flex;
    color: var(--text);
    background: var(--bg-main);
    font-size: 100%;
    line-height: var(--margin-md);
}

main {
    flex-grow: 1;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

main .container {
    width: calc(100% - var(--margin-xl));
    max-width: 33.5rem;
    margin-block: var(--margin-xl);
    margin-inline: auto;
}

a {
    text-decoration: none;
    transition: color 0.5s ease-in-out;
}

a:link, a:visited {
    color: var(--primary);
}

a:hover, a:active {
    color: var(--secondary);
}

abbr, span.def {
    cursor: help;
}

b, strong {
    font-weight: var(--font-weight-bold);
}

i, em {
    font-style: italic;
}

h1 {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-semi);
    line-height: var(--margin-xl);
}

h2 {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-semi);
    line-height: var(--margin-lg);
}

h3 {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-semi);
    line-height: var(--margin-md);
}

h4 {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-semi);
    line-height: var(--margin-md);
}

h5 {
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-semi);
    line-height: var(--margin-md);
    text-transform: uppercase;
}


/* --- ids/classes --- */

.eventbar {
    width: 100%;
    height: 0.25rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background: var(--primary);
    background-position: center center;
}

.page-center {
    margin-block-start: -2rem;
    margin-block-end: 0;
    margin-inline: auto;
}

#camoji {
    width: 4rem;
    height: 4rem;
    margin-block-start: 0;
    margin-block-end: calc(var(--margin-xs) / 2);
    margin-inline: auto;
    position: relative;
    overflow: visible;
    background: var(--bg-alt);
    border: 1px solid var(--borders);
    border-radius: 50%;
}

#camoji img {
    width: 5rem;
    height: 5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#intro h1, #intro p {
    display: inline;
    font-weight: var(--font-weight-normal);
    font-size: 1rem;
    line-height: var(--margin-md);
}


/* --- inter font --- */

@font-face {
    font-family: inter;
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url("/fonts/InterVariable.woff2") format("woff2");
}

@font-face {
    font-family: inter;
    font-style: italic;
    font-weight: 100 900;
    font-display: swap;
    src: url("/fonts/InterVariable-Italic.woff2") format("woff2");
}

@font-feature-values inter {
    @character-variant {
        cv01: 1; cv02: 2; cv03: 3; cv04: 4; cv05: 5; cv06: 6; cv07: 7; cv08: 8;
        cv09: 9; cv10: 10; cv11: 11; cv12: 12; cv13: 13;
        alt-1:            1; /* Alternate one */
        alt-3:            9; /* Flat-top three */
        open-4:           2; /* Open four */
        open-6:           3; /* Open six */
        open-9:           4; /* Open nine */
        lc-l-with-tail:   5; /* Lower-case L with tail */
        simplified-u:     6; /* Simplified u */
        alt-double-s:     7; /* Alternate German double s */
        uc-i-with-serif:  8; /* Upper-case i with serif */
        uc-g-with-spur:  10; /* Capital G with spur */
        single-story-a:  11; /* Single-story a */
        compact-lc-f:    12; /* Compact f */
        compact-lc-t:    13; /* Compact t */
    }

    @styleset {
        ss01: 1; ss02: 2; ss03: 3; ss04: 4; ss05: 5; ss06: 6; ss07: 7; ss08: 8;
        open-digits: 1;                /* Open digits */
        disambiguation: 2;             /* Disambiguation (with zero) */
        disambiguation-except-zero: 4; /* Disambiguation (no zero) */
        round-quotes-and-commas: 3;    /* Round quotes &amp; commas */
        square-punctuation: 7;         /* Square punctuation */
        square-quotes: 8;              /* Square quotes */
        circled-characters: 5;         /* Circled characters */
        squared-characters: 6;         /* Squared characters */
    }
}
